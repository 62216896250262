import CreamCaramel from "../../Images/Cream Caramel.png";
import CreamTin from "../../Images/cream tin.png";
import Evap170 from "../../Images/Evaporated Milk 170g.png";
import Evap410 from "../../Images/Evaporated Milk 410g.png";
import FoulMedammes from "../../Images/Foul Medammes.png";
import FreshoCondensedMilk from "../../Images/Fresho Condensed Milk.png";
import MarbleCondensedMilk from "../../Images/Marble Condensed Milk.png";
import PineappleSlice from "../../Images/Pineapple Slice.png";
import FreshoStrawberry from "../../Images/fresho cream strawberry flavour.png";
import FreshoHoneyCream from "../../Images/fresho cream honey flavour.png";

export const ProductsData = [
  {
    image: FreshoCondensedMilk,
    text: "Fresho Condensed Milk (390g)",
    details:
      "Fresho sweetened condensed milk is thick in texture, rich in nutrients and perfectly suited as an ingredient in various food recipes like cookies, desserts, cakes & puddings. Made from pasteurized cow milk, sweetened with sugar and enriched with palm oil and Vitamins B1, A, and D3.",
    ingredients:
      "It contains stabilizers [Disodium Phosphate (E339), Carrageenan (E407)], Emulsifier (Soy Lecithin E322) as permitted food conditioners, Vitamin B1, A, and D3",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "323 Kcal",
    nutritionbodyr1t3: "Vitamin A",
    nutritionbodyr1t4: "700 IU",
    nutritionbodyr2t1: "Fat",
    nutritionbodyr2t2: "8.5 g",
    nutritionbodyr2t3: "Vitamin B1",
    nutritionbodyr2t4: "0.9 mg",
    nutritionbodyr3t1: "Protein",
    nutritionbodyr3t2: "3.5 g",
    nutritionbodyr3t3: "Vitamin D3",
    nutritionbodyr3t4: "120 IU",
    nutritionbodyr4t1: "Carbohydrate",
    nutritionbodyr4t2: "58 g",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "",
    nutritionbodyr5t1: "",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "",
    nutritionbodyr6t1: "",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "",
    nutritionbodyr7t1: "",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: PineappleSlice,
    text: "Fresho Pineapple Slice (565g)",
    details:
      "Fresho Pineapple Slices (565g) is a delightful product that gives you perfectly sliced pineapples, ready to eat & release its tropical sweetness into your recipes of main dishes, desserts, salads & beverages. Pineapples are good source of vitamin C, vitamin B, thiamin, manganese & healthy antioxidants.",
    ingredients:
      "Round Pineapple Slices, Water, Sugar, Citric Acid (Acidity Regulator)",
    nutritionhead: "Nutritional Information About 4 Servings Per container",
    nutritionbodyr1t1: "Calories",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "91",
    nutritionbodyr1t4: "",
    nutritionbodyr2t1: "Daily Value%",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "",
    nutritionbodyr3t1: "Total Fat",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "0 g",
    nutritionbodyr3t4: "0%",
    nutritionbodyr4t1: "Saturated Fat",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "0 g",
    nutritionbodyr4t4: "0%",
    nutritionbodyr5t1: "Trans Fat",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "0 g",
    nutritionbodyr5t4: "",
    nutritionbodyr6t1: "Cholesterol",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "0 mg",
    nutritionbodyr6t4: "0%",
    nutritionbodyr7t1: "Sodium",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "0 mg",
    nutritionbodyr7t4: "0%",
    nutritionbodyr8t1: "Carbohydrate",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "22 g",
    nutritionbodyr8t4: "8%",
    nutritionbodyr9t1: "Dietary Fiber",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "0.8 g",
    nutritionbodyr9t4: "3%",
    nutritionbodyr10t1: "Total Sugars",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "16 g",
    nutritionbodyr10t4: "0%",
    nutritionbodyr11t1: "Added Sugars",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "15g",
    nutritionbodyr11t4: "30%",
    nutritionbodyr12t1: "Protein",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "0 g",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "Vitamin D",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "0 mg",
    nutritionbodyr13t4: "0%",
    nutritionbodyr14t1: "Calcium",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "9.80 mg",
    nutritionbodyr14t4: "1%",
    nutritionbodyr15t1: "Iron",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "0 g",
    nutritionbodyr15t4: "0%",
    nutritionbodyr16t1: "Potassium",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "111 mg",
    nutritionbodyr16t4: "6%",
    nutritionbodyr17t1: "Vitamin C",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "5.2 mg",
    nutritionbodyr17t4: "5%",
    nutritionbodyr18t1: "Vitamin A",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "0 mg",
    nutritionbodyr18t4: "0%",
  },
  {
    image: CreamTin,
    text: "Fresho Cream (150g)",
    details:
      "Fresho Cream is a premium quality dairy product carrying cream made from natural milk and provided in a form that cannot get any richer or creamier. Fresho cream is a rich ingredient especially in the Arabic cuisine favorite béchamel pasta and a variety of cream pies, smoothies, sweets, casseroles & lasagna dishes.",
    ingredients:
      "Skimmed Cow's Milk Powder,Pure Refined Vegetable Fat (palm oil) 23%, Emulsifiers (E471 from vegetable source, E407,E410,E339) & Stabilizers (E401)",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "232 kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "2.2 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "3.8 g",
    nutritionbodyr4t1: "Fat",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "23.2 g",
    nutritionbodyr5t1: "Dietary Fiber",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "0.7 g",
    nutritionbodyr6t1: "Sodium",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "56 mg",
    nutritionbodyr7t1: "",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: Evap170,
    text: "Fresho Evaporated Milk (170g)",
    details:
      "Fresho evaporated milk is perfect for your creamy recipes and for an ideal cup of tea. A high-quality product made from skimmed milk with less fat and no sugar content but with the same bone-building nutrients of protein, calcium, vitamins D & A.",
    ingredients:
      "Skimmed Cow's Milk Powder,Vegetable Fat,Emulsifiers(E471 from vegetable source, E407, E322, E339),Total Solids(25%),Fat(7.5%)",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "137 kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "5.9 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "10.6 g",
    nutritionbodyr4t1: "Fat",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "7.5 g",
    nutritionbodyr5t1: "Calcium",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "220 mg",
    nutritionbodyr6t1: "Sodium",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "85 g",
    nutritionbodyr7t1: "",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: Evap410,
    text: "Fresho Evaporated Milk (410g)",
    details:
      "Fresho evaporated milk is perfect for your creamy recipes and for an ideal cup of tea. A high-quality product made from skimmed milk with less fat and no sugar content but with the same bone-building nutrients of protein, calcium, vitamins D & A.",
    ingredients:
      "Skimmed Cow's Milk Powder,Vegetable Fat,Emulsifiers(E471 from vegetable source, E407, E322, E339),Total Solids(25%),Fat(7.5%)",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "137 kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "5.9 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "10.6 g",
    nutritionbodyr4t1: "Fat",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "7.5 g",
    nutritionbodyr5t1: "Calcium",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "220 mg",
    nutritionbodyr6t1: "Sodium",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "85 g",
    nutritionbodyr7t1: "",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: MarbleCondensedMilk,
    text: "Marble Condensed Milk (390g)",
    details:
      "Marble sweetened condensed milk is thick in texture, rich in nutrients and perfectly suited as an ingredient in various food recipes like cookies, desserts, cakes & puddings. Made from pasteurized cow milk, sweetened with sugar and enriched with palm oil and Vitamins B1, A, and D3.",
    ingredients:
      "It contains stabilizers [Disodium Phosphate (E339), Carrageenan (E407)], Emulsifier (Soy Lecithin E322) as permitted food conditioners, Vitamin B1, A, and D3",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "323 Kcal",
    nutritionbodyr1t3: "Vitamin A",
    nutritionbodyr1t4: "700 IU",
    nutritionbodyr2t1: "Fat",
    nutritionbodyr2t2: "8.5 g",
    nutritionbodyr2t3: "Vitamin B1",
    nutritionbodyr2t4: "0.9 mg",
    nutritionbodyr3t1: "Protein",
    nutritionbodyr3t2: "3.5 g",
    nutritionbodyr3t3: "Vitamin D3",
    nutritionbodyr3t4: "120 IU",
    nutritionbodyr4t1: "Carbohydrate",
    nutritionbodyr4t2: "58 g",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "",
    nutritionbodyr5t1: "",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "",
    nutritionbodyr6t1: "",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "",
    nutritionbodyr7t1: "",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: FoulMedammes,
    text: "Fresho Plain Fava Beans (400g)",
    details:
      "Fresho Plain Fava Beans grant you 400g of premium quality fava beans, cooked to the right point which make them both perfect to add some intact nutritious beans to your salads and vegetarian dishes and to be mashed to its buttery form for your pastier recipes.",
    ingredients:
      "Premium Fava Beans,Water, Salt, Sugar, Citric Acid (Acidity Regulator), Calcium Disodium EDTA (E385) for Color Retention",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "",
    nutritionbodyr1t2: "Daily Value",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "%",
    nutritionbodyr2t1: "Total Fat",
    nutritionbodyr2t2: "1g",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "2",
    nutritionbodyr3t1: "Saturated Fat",
    nutritionbodyr3t2: "0 g",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "0",
    nutritionbodyr4t1: "Trans Fat",
    nutritionbodyr4t2: "0 g",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "7.5 g",
    nutritionbodyr5t1: "Cholesterol",
    nutritionbodyr5t2: "0 mg",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "0",
    nutritionbodyr6t1: "Sodium",
    nutritionbodyr6t2: "430 mg",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "20",
    nutritionbodyr7t1: "Total",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "",
    nutritionbodyr8t1: "Carbohydrate",
    nutritionbodyr8t2: "15 g",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "5",
    nutritionbodyr9t1: "Dietary Fiber",
    nutritionbodyr9t2: "7 g",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "28",
    nutritionbodyr10t1: "Sugar",
    nutritionbodyr10t2: "1 g",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "Protein",
    nutritionbodyr11t2: "7 g",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "Vitamin A",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "0%",
    nutritionbodyr13t1: "Vitamin C",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "6%",
    nutritionbodyr14t1: "Calcium	",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "2%",
    nutritionbodyr15t1: "Iron",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "8%",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: CreamCaramel,
    text: "Fresho Cream Caramel (70g)",
    details:
      "Fresho crème caramel provides ready to make crème caramel powder to ease your way into serving one of the most beloved desserts all over the world. Includes 2 sachets, one for the crème powder, and the other for the caramel. Easily prepared to give you the sublime silky contradiction that is called “Crème Caramel”.",
    ingredients:
      "Sugar,Carrageenan (E407), Disodium Phosphate (E339), Artificial Vanilla FlavourNatural Colours ((Annatto {E160b}; Curcumin {E100}),Topping :Sugar,Color (Caramel;(E150c/E150d),Artificial Cream Caramel Flavor",
    nutritionhead: "Nutritional Value per 100 g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "374kj/88kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "2.8 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "15.7 g",
    nutritionbodyr4t1: "Sugar",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "13.7 g",
    nutritionbodyr5t1: "Fat",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "1.4 g",
    nutritionbodyr6t1: "Saturated Fat",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "0.93 g",
    nutritionbodyr7t1: "Sodium",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "0.13 g",
    nutritionbodyr8t1: "Salt Equiv",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "0.34 g",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: FreshoStrawberry,
    text: "Fresho Cream Strawberry Flavour",
    details:
      "Fresho cream with strawberry flavor will come in handy for all those delicious home-cook recipes of cream pies, smoothies, sweets, casseroles & lasagna dishes to name a few. Created from milk cream with healthy fats powdered and whipped and enriched with palm oil, milk fats, vitamins A, C & D, calcium, potassium, magnesium & natural strawberry flavor.",
    ingredients:
      "Sugar,Carrageenan (E407), Disodium Phosphate (E339), Artificial Vanilla FlavourNatural Colours ((Annatto {E160b}; Curcumin {E100}),Topping :Sugar,Color (Caramel;(E150c/E150d),Artificial Cream Caramel Flavor",
    nutritionhead: "Amount per 100g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "270 kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "2 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "11.3 g",
    nutritionbodyr4t1: "Total Sugars",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "9.5 g",
    nutritionbodyr5t1: "Fat",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "23 g",
    nutritionbodyr6t1: "Dietary Fiber",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "0.6 g",
    nutritionbodyr7t1: "Sodium",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "56 mg",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
  {
    image: FreshoHoneyCream,
    text: "Fresho Cream Strawberry Flavour",
    details:
      "Fresho cream with honey flavor will come in handy for all those delicious home-cook recipes of cream pies, smoothies, sweets, casseroles & lasagna dishes to name a few. Created from milk cream with healthy fats powdered and whipped and enriched with palm oil, milk fats, vitamins A, C & D, calcium, potassium, magnesium & natural honey flavor.",
    ingredients:
      "Sugar,Carrageenan (E407), Disodium Phosphate (E339), Artificial Vanilla FlavourNatural Colours ((Annatto {E160b}; Curcumin {E100}),Topping :Sugar,Color (Caramel;(E150c/E150d),Artificial Cream Caramel Flavor",
    nutritionhead: "Amount per 100g",
    nutritionbodyr1t1: "Energy",
    nutritionbodyr1t2: "",
    nutritionbodyr1t3: "",
    nutritionbodyr1t4: "270 kcal",
    nutritionbodyr2t1: "Protein",
    nutritionbodyr2t2: "",
    nutritionbodyr2t3: "",
    nutritionbodyr2t4: "2 g",
    nutritionbodyr3t1: "Carbohydrate",
    nutritionbodyr3t2: "",
    nutritionbodyr3t3: "",
    nutritionbodyr3t4: "11.3 g",
    nutritionbodyr4t1: "Total Sugars",
    nutritionbodyr4t2: "",
    nutritionbodyr4t3: "",
    nutritionbodyr4t4: "9.5 g",
    nutritionbodyr5t1: "Fat",
    nutritionbodyr5t2: "",
    nutritionbodyr5t3: "",
    nutritionbodyr5t4: "23 g",
    nutritionbodyr6t1: "Dietary Fiber",
    nutritionbodyr6t2: "",
    nutritionbodyr6t3: "",
    nutritionbodyr6t4: "0.6 g",
    nutritionbodyr7t1: "Sodium",
    nutritionbodyr7t2: "",
    nutritionbodyr7t3: "",
    nutritionbodyr7t4: "56 mg",
    nutritionbodyr8t1: "",
    nutritionbodyr8t2: "",
    nutritionbodyr8t3: "",
    nutritionbodyr8t4: "",
    nutritionbodyr9t1: "",
    nutritionbodyr9t2: "",
    nutritionbodyr9t3: "",
    nutritionbodyr9t4: "",
    nutritionbodyr10t1: "",
    nutritionbodyr10t2: "",
    nutritionbodyr10t3: "",
    nutritionbodyr10t4: "",
    nutritionbodyr11t1: "",
    nutritionbodyr11t2: "",
    nutritionbodyr11t3: "",
    nutritionbodyr11t4: "",
    nutritionbodyr12t1: "",
    nutritionbodyr12t2: "",
    nutritionbodyr12t3: "",
    nutritionbodyr12t4: "",
    nutritionbodyr13t1: "",
    nutritionbodyr13t2: "",
    nutritionbodyr13t3: "",
    nutritionbodyr13t4: "",
    nutritionbodyr14t1: "",
    nutritionbodyr14t2: "",
    nutritionbodyr14t3: "",
    nutritionbodyr14t4: "",
    nutritionbodyr15t1: "",
    nutritionbodyr15t2: "",
    nutritionbodyr15t3: "",
    nutritionbodyr15t4: "",
    nutritionbodyr16t1: "",
    nutritionbodyr16t2: "",
    nutritionbodyr16t3: "",
    nutritionbodyr16t4: "",
    nutritionbodyr17t1: "",
    nutritionbodyr17t2: "",
    nutritionbodyr17t3: "",
    nutritionbodyr17t4: "",
    nutritionbodyr18t1: "",
    nutritionbodyr18t2: "",
    nutritionbodyr18t3: "",
    nutritionbodyr18t4: "",
  },
];
