import React from "react";
import { ProductsData } from "./productSectionData";
import "./style.css";
import { Link } from "react-router-dom";
import * as HiIcons from "react-icons/hi";
const ProductSection = () => {
  return (
    <div>
      <div className="products-main-div">
        <h1>Explore Fresho Product Range</h1>
        {/* <p>Fresho offer high quality products to the consumers</p> */}
        <div className="card-landing-div">
          {ProductsData.map((data) => (
            <div
              className="landingSectionMain"
              data-aos="fade-right"
              key={data.id}
            >
              <div className="card">
                <div className="card-title">
                  <h3 className="title-heading">{data.title}</h3>
                  <p className="card-tagline">{data.tagline}</p>
                </div>
                <div className="card-body">
                  <img src={data.img} alt={data.alt} className="card-img" />
                </div>
                <div className="card-footer">
                  <Link to={data.path}>
                    <button className="card-button">
                      {data.button}
                      <HiIcons.HiArrowNarrowRight className="card-btn-icon" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/products">
          <button className="card-ending-btn">
            View All
            <HiIcons.HiArrowNarrowRight className="card-btn-icon" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProductSection;
