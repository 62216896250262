import ImageOne from "../../Images/Evaporated Milk 170g.png";
import ImageTwo from "../../Images/cream tin.png";
import ImageThree from "../../Images/Marble Condensed Milk.png";
export const ProductsData = [
  {
    id: 1,
    title: "Fresho Evaporated Milk (170g)",
    img: ImageOne,
    alt: "Evaporated Milk Mockup Design",
    button: "View Product",
    tagline: "Premium Quality",
    path: "/products",
  },
  {
    id: 2,
    title: "Fresho Cream (150g)",
    img: ImageTwo,
    alt: "Cream Mockup Design",
    button: "View Product",
    tagline: "Premium Quality",
    path: "/products",
  },
  {
    id: 3,
    title: "Marble Condensed Milk (390g)",
    img: ImageThree,
    alt: "Marble Condensed Milk Mockup Design",
    button: "View Product",
    tagline: "Premium Quality",
    path: "/products",
  },
];
