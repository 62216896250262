import ImageOne from "../../Images/Pineapple Slice Mockup.jpg";
import ImageTwo from "../../Images/Evaporated Milk Mockup.jpg";
import ImageThree from "../../Images/Cream Mockup.jpg";
export const LatestDevData = [
  {
    id: 1,
    title: "Pineapple Slice",
    button: "View Product",
    tagline: "Premium Quality",
    image: ImageOne,
    alt: "Pineapple Slice Image",
  },
  {
    id: 2,
    title: "Evaporated Milk",
    button: "View Product",
    tagline: "Premium Quality",
    image: ImageTwo,
    alt: "Evaporated Milk Image",
  },
  {
    id: 3,
    title: "Condensed Milk",
    button: "View Product",
    tagline: "Premium Quality",
    image: ImageThree,
    alt: "Condensed Milk Image",
  },
];
