import React from "react";
import ImageTwo from "../../Images/FavaBeans Mockup2.jpg";
import "./style.css";
const LandingSection = () => {
  return (
    <div className="carousel">
      <img src={ImageTwo} alt="Fava Beans Mockup" className="bg-img" />
    </div>
  );
};

export default LandingSection;
