export const FooterData = [
  {
    id:1,
    title:"Home",
    path:"/"
  },
  {
    id: 2,
    title: "About",
    path: "/about",
  },
  {
    id: 3,
    title: "Contact",
    path: "/contact",
  },
  {
    id: 4,
    title: "Products",
    path: "/products",
  },
  {
    id: 5,
    title: "Catalogue",
    path: "Catalogue",
  },
];
