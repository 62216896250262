import React from "react";
import { LatestDevData } from "./LatestDevData";
import "./style.css";
import { Link } from "react-router-dom";
const LatestDev = () => {
  return (
    <div className="main-div">
      <h1>Latest Products</h1>
      <p>Fresho always developing their products to satisfy consumers’ needs</p>
      <div className="products-section-div">
        {LatestDevData.map((item) => (
          <div className="card-main-div" key={item.id}>
            <Link to="/products">
              <figure className="figure">
                <div className="image-div">
                  <img
                    src={item.image}
                    className="LatestDevImg"
                    alt={item.alt}
                  />
                  <figcaption>
                    <h3>{item.title}</h3>
                    <p>{item.tagline}</p>
                    <h5>{item.button}</h5>
                  </figcaption>
                </div>
              </figure>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default LatestDev;
