import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import BannerImage from "../../Images/Banner.jpg";
import "./style.css";
import { ProductsData } from "./ProductsData";
import { Card, Accordion } from "react-bootstrap";
const Products = () => {
  useEffect(() => {
    document.title = "Fresho - Products";
  }, []);
  return (
    <div>
      <div className="products-page">
        <Navbar />
        <img src={BannerImage} alt="Banner" className="products-banner-image" />
        <div className="products-heading">
          <h1>Products</h1>
          <p>
            The largest integrated food industry in the Middle East with
            ultramodern facilities
          </p>
        </div>
        <hr className="opening-hr" />
        <div className="products-page-body">
          {ProductsData.map((item) => (
            <div className="products-page-card">
              <div className="products-page-img-div">
                <img src={item.image} alt="" className="products-page-image" />
              </div>
              <h4 className="products-page-text">{item.text}</h4>
              <Accordion className="accordion">
                <div>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className="accordion-toggle"
                  >
                    Details
                    <span>+</span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="products-card-body">
                      {item.details}
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
                <div>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    className="accordion-toggle"
                  >
                    Ingredients
                    <span>+</span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="products-card-body">
                      {item.ingredients}
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
                <div>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="2"
                    className="accordion-toggle"
                  >
                    Nutritional Information
                    <span>+</span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="products-card-body">
                      <p className="nutritionhead">{item.nutritionhead}</p>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr1t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr1t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr1t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr1t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr2t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr2t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr2t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr2t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr3t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr3t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr3t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr3t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr4t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr4t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr4t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr4t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr5t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr5t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr5t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr5t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr6t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr6t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr6t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr6t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr7t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr7t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr7t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr7t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr8t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr8t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr8t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr8t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr9t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr9t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr9t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr9t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr10t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr10t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr10t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr10t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr11t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr11t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr11t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr11t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr12t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr12t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr12t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr12t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr13t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr13t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr13t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr13t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr14t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr14t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr14t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr14t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr15t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr15t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr15t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr15t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr16t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr16t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr16t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr16t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr17t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr17t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr17t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr17t4}
                        </td>
                      </tr>
                      <tr className="products-row">
                        <td className="product-cell">
                          {item.nutritionbodyr18t1}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr18t2}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr18t3}
                        </td>
                        <td className="product-cell">
                          {item.nutritionbodyr18t4}
                        </td>
                      </tr>
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Products;
