import React from "react";
import Banner from "../../Images/Banner.jpg";
import "./style.css";
const banner = () => {
  return (
    <div className="banner-image">
      <img src={Banner} alt="Banner" />
    </div>
  );
};

export default banner;
