import React, { useEffect } from "react";
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import LandingSection from "../../Components/LandingSection";
import LatestDev from "../../Components/Latest Development";
import Navbar from "../../Components/Navbar";
import ProductSection from "../../Components/Products Section";
const Home = () => {
  useEffect(() => {
    document.title = "Fresho | Wholesale FMCG Supplier & Distributor";
  }, []);
  return (
    <div>
      <LandingSection />
      <Navbar />
      <LatestDev />
      <ProductSection />
      <Banner />
      <Footer />
    </div>
  );
};

export default Home;
