import React from "react";
import { FooterData } from "./Footer Data";
import * as CgIcons from "react-icons/cg";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import Catalogue from "../../Images/catalogue.pdf";
import { Link } from "react-router-dom";
import "./style.css";
const Footer = () => {
  return (
    <div>
      <div className="footer-container">
        <div className="useful-links-panel">
          {FooterData.map((item) => (
            <div className="page-link" key={item.id}>
              {item.id !== 5 ? (
                <Link to={item.path}>
                  <p>{item.title}</p>
                </Link>
              ) : (
                <a href={Catalogue}>
                <p>
                  {item.title}
                  </p>
                </a>
              )}
            </div>
          ))}
        </div>
        <div className="icons-main-div">
          <div className="icons-div">
            <a href="https://www.facebook.com/freshofoodstuff/">
              <CgIcons.CgFacebook className="icon" />
            </a>
            <a href="https://www.instagram.com/fresho.ae/">
              <AiIcons.AiOutlineInstagram className="icon" />
            </a>
            <a href="mailto:info@fresho.ae">
              <FiIcons.FiMail className="icon" />
            </a>
          </div>
        </div>
        <div className="copyright-div">
          <p>
            Copyright &copy; by <span>FRESHO FOODS</span>. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
